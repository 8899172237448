@import "bootstrap/dist/css/bootstrap.min.css";
@import "@core/scss/core.scss";
@import "./assets/scss/styles";

 html body {
     //background-color: #8bd0ff1a;
     overflow-x: hidden;
     overflow-y: scroll;
 }

.card {
    box-shadow: 0 4px 12px #00000045;
}

.ml-05 {
    margin-left: 0.5rem;
}

.cursor-loader {
    cursor: wait;
    background-color: red;

    * {
        cursor: wait !important;
    }
}

.ps .ps__rail-y:hover {
    background: transparent;
}

.btn-shine-green {
    background: #6ccda1 !important;
    border: 1px solid #6ccda1 !important;
    color: white !important;
}

.placeholder-content {
    height: 25px;
    overflow: hidden;
    background: #000;
    position: relative;
    margin: 0.5rem 0;

    // Animation
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; // Animation Area

    &_item {
        width: 100%;
        height: 20px;
        position: absolute;
        background: #fff;
        z-index: 2;

        &:after,
        &:before {
            width: inherit;
            height: inherit;
            content: "";
            position: absolute;
        }

        &:nth-child(1) {
            top: 0;
            left: 0;
        }

        &:nth-child(2) {
            top: 20px;
            left: 0;
            width: 10%;
            height: 90px;
        }

        &:nth-child(3) {
            top: 0px;
            left: 0;
            width: 10%;
            height: 100%;
        }

        &:nth-child(4) {
            top: 20px;
            width: 20px;
            left: 170px;
            height: 90px;
        }

        &:nth-child(5) {
            top: 40px;
            left: 190px;
            height: 12px;
        }

        &:nth-child(6) {
            top: 75px;
            left: 190px;
            height: 12px;
        }

        &:nth-child(7) {
            top: 20px;
            right: 0;
            width: 23%;
            height: 20px;
        }

        &:nth-child(8) {
            top: 0;
            right: 0;
            width: 10%;
            height: 100%;
        }

        &:nth-child(9) {
            top: 110px;
            height: 17px;
            left: 0;
        }

        &:nth-child(10) {
            top: 149px;
            height: 12px;
            left: 0;
        }

        &:nth-child(11) {
            top: 183px;
            left: 0;
            height: 100%;
        }
    }
}

body.dark-layout {
    .pagination_xo {
        background-color: #283046;
    }
}

.data-table-hide-footer {
    margin-bottom: 0;

    .datatable-footer-inner {
        display: none !important;
    }
}

@keyframes placeholderAnimate {
    0% {
        background-position: -650px 0;
    }

    100% {
        background-position: 650px 0;
    }
}

.delete_btn {
    color: rgba(231, 58, 58, 0.7) !important;

    &:hover {
        color: rgba(171, 0, 0, 0.7) !important;
    }
}

.edit_btn {
    color: #7367f0 !important;

    &:hover {
        color: #4337c5;
    }
}

.loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.apexcharts-toolbar {
    display: none !important;
}

@media only print {
    html .content.app-content {
        background-color: #f8f8f8 !important;
        print-color-adjust: exact;
        margin: 0;
        padding: 0;
        padding: 0.7rem 0;
    }

    body {
        padding: 0.7rem 0;
    }

    @page {
        size: A2;
        margin: 0;
        padding: 0.7rem 0;
    }

    #apex-column-charts {
        margin-top: 150px;
    }

    .print-only {
        display: flex;
    }

    .print-none,
    .main-menu,
    .footer,
    .vertical-menu,
    .card.ng-star-inserted {
        display: none !important;
    }

    .row {
        margin-bottom: 20px; /* Adjust margin as needed */
    }

    .table-responsive {
        overflow-x: auto;
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        border-collapse: collapse;
        min-width: 0;
        font-size: 0.875rem;
        border: 1px solid #dee2e6;
    }

    .table th,
    .table td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    .table th {
        background-color: #f8f9fa;
        border-bottom: 2px solid #dee2e6;
    }

    .table th.print-none,
    .table td.print-none {
        display: none;
    }

    .table-responsive::-webkit-scrollbar {
        display: none;
    }

    .table-hover tbody tr:hover {
        background-color: #f8f9fa;
    }

    .cell-line-height {
        line-height: 1.2;
    }

    .match-height {
        page-break-inside: avoid;
    }

    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right: 0.25em solid transparent;
        border-radius: 50%;
        animation: spinner-border 0.75s linear infinite;
    }

    .table tbody tr td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.ngx-datatable.bootstrap .datatable-footer .page-count {
    display: none;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    margin: 0 10px;
    display: none;
    vertical-align: top;
}
